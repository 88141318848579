/* eslint-disable import/prefer-default-export */
import 'whatwg-fetch'; // polyfill fetch
import log from 'loglevel';
import * as Sentry from '@sentry/browser';

export const onClientEntry = () => {
  if (process.env.NODE_ENV === 'development') {
    log.setLevel('info');
  }
  if (['production', 'stage'].indexOf(process.env.NODE_ENV) !== -1) {
    // bootstrap sentry
    Sentry.init({
      dsn: 'https://8b87b2546496422da8179e278830a24e@sentry.io/1542035',
      environment: process.env.NODE_ENV,
      release: `hirsch-apotheke-korbach@${process.env.npm_package_version}`,
    });
  }
  log.info('STARTED THE APP');
};
